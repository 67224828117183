<template>
  <div class="wrapper">
    <form class="login inner-wrapper" @submit.prevent="validateAll">
      <div class="accounts-form">
        <h1
          class="accounts-form__bi-header cursor"
          @click="$router.push('/introduction/main')"
        >
          로그인
        </h1>
        <InputField
          placeholder="아이디(이메일)"
          v-model="username"
          class="accounts-form__outlined-text"
          case="email"
          @validate="validate"
        ></InputField>
        <p class="accounts-form__form-error" v-if="error.email">
          {{ error.email }}
        </p>
        <InputField
          placeholder="비밀번호"
          v-model="password"
          class="accounts-form__outlined-text"
          type="password"
          case="password"
          @validate="validate"
        ></InputField>
        <p class="accounts-form__form-error" v-if="error.password">
          {{ error.password }}
        </p>
        <div class="d-flex df-row">
          <label class="login__saveId">
            <input type="checkbox" v-model="idSave" />
            <span></span>
          </label>
          <div>아이디 저장</div>
          <div class="spacer"></div>
          <div class="login__passwordReset">
            <a
              href="/accounts/password-reset"
              class="accounts-link__right ga_f_500"
            >
              비밀번호 찾기
            </a>
          </div>
        </div>
        <button
          class="login--submit"
          type="submit"
          :class="[
            username && password
              ? 'accounts-form__submit'
              : 'accounts-form__submit__disabled'
          ]"
        >
          로그인하기
        </button>
        <div class="login__signup">
          아직 회원이 아니신가요?
          <a href="/accounts/signup" class="accounts-link__highlighted"
            >회원가입하러 가기</a
          >
        </div>
      </div>
    </form>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import {setAuthToken, removeAuthToken, getAuthToken} from "@/utils/auth.js";
import {verifyLastPasswordChanged} from "@/utils/user.js";
import {createNamespacedHelpers} from "vuex";
import "@/assets/scss/accounts/basis.scss";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {
      inactivatedAt: null,
      username: "",
      password: "",
      idSave: false,
      error: {email: "", password: ""},
      callback: "",
      checkedEmail: false,
      checkedPassword: false
    };
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoginData"])
  },
  mounted() {
    // Setting Callback URL
    if (this.$route.query.callback) {
      this.callback = this.$route.query.callback;
    }

    // 이미 로그인된 상태인지 확인
    if (getAuthToken()) {
      this.redirectCallback();
    }

    // 아이디 저장한 상태일때
    if (localStorage.getItem("savedId")) {
      this.username = localStorage.getItem("savedId");
      this.idSave = true;
    }
  },
  methods: {
    redirectCallback() {
      !this.callback
        ? this.$router.replace("/admin/dashboard")
        : this.callback.match(/^http(s*):\/\//)
        ? (location.href = this.callback)
        : this.$router.replace(this.callback);
    },
    //input 유효성 검사
    validate(obj) {
      const _case = obj.case;

      if (_case === "email") {
        if (!this.username) {
          this.error.email = "아이디를 입력해주세요.";
          this.checkedEmail = false;
        } else if (
          !this.username.match(
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
          )
        ) {
          this.error.email = "이메일 형식이 맞지 않습니다.";
          this.checkedEmail = false;
        } else {
          this.error.email = "";
          this.checkedEmail = true;
        }
      }

      if (_case === "password") {
        if (!this.password) {
          this.error.password = "비밀번호를 입력해주세요.";
          this.checkedPassword = false;
        } else {
          this.error.password = "";
          this.checkedPassword = true;
        }
      }
    },
    //로그인하기 버튼 눌렀을때 모든 입력창 유효성 검사
    validateAll() {
      if (!this.username || !this.password) return false;

      const inputs = [
        {case: "email", value: this.email},
        {case: "password", value: this.password}
      ];

      inputs.forEach(item => {
        this.validate(item);
      });

      //모든 필수값 유효성 검사 성공시
      if (this.checkedEmail && this.checkedPassword) this.userLogin();
    },
    //로그인
    userLogin() {
      let payload = {};
      payload.username = this.username;
      payload.password = this.password;

      this.actLogin(payload).then(response => {
        switch (response.status) {
          case 200:
            setAuthToken(response.data.accessToken);
            if (response.data?.inactivatedAt) {
              this.inactivatedAt = response.data?.inactivatedAt;
            }
            this.setLoginStatus(true);
            this.userLogged();
            this.$store.dispatch("ModuleUser/actUserLog", {
              eventName: "로그인",
              requestLocation: "큐픽 > 로그인"
            });

            // 아이디 저장 체크
            if (this.idSave) {
              localStorage.setItem("savedId", this.username);
            } else {
              if (localStorage.getItem("savedId")) {
                localStorage.removeItem("savedId");
              }
            }

            break;

          case 403:
            //회원가입 후, 이메일 인증 전
            if (response.data.errors[0].code === "user.not_email_verified") {
              this.$router.push(
                "/accounts/signup/certify/notice?email=" + this.username
              );
            } else {
              this.$refs.toast.show(response.data.errors[0].userMsg);
            }
            break;

          case 404:
            this.$refs.toast.show("가입되어있는 아이디가 아닙니다.");
            break;

          case 422:
            if (response.data.errors[0].code == "validation.email") {
              this.$refs.toast.show("이메일 형식이 맞지 않습니다.");
            } else {
              this.$refs.toast.show("비밀번호가 맞지 않습니다.");
            }
            break;

          default:
            this.$refs.toast.show(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    //로그인된 회원 정보 획득
    userLogged() {
      this.actLogged().then(response => {
        switch (response.status) {
          case 200:
            this.checkLogged(response.data?.lastPasswordChangedAt);
            break;

          default:
            removeAuthToken();
            this.$refs.toast.show(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    //휴면 회원, 비밀번호 변경 회원 검증
    checkLogged(lastChanged) {
      if (this.inactivatedAt) {
        this.$router.replace("/accounts/inactivate");
      } else {
        //6개월 이상 경과 시, 비밀번호 변경 캠페인 이동
        if (verifyLastPasswordChanged(lastChanged)) {
          this.redirectCallback();
        } else {
          this.$router.replace(
            "/accounts/password-change?campaign=y&callback=" + this.callback
          );
        }
      }
    },
    ...ModuleAuth.mapActions(["actLogin", "actLogged"]),
    ...ModuleAuth.mapMutations(["setLoginStatus"])
  },
  components: {
    toastMsg: () => import("@/components/accounts/toast-message.vue")
  }
};
</script>

<style>
.login__saveId {
  float: left;
  height: 24px;
  margin-bottom: 24px;
  font-size: 16px;
}
.login__saveId input {
  margin-right: 4px;
  vertical-align: -1px;
}
.login__passwordReset {
  float: right;
  height: 24px;
  position: relative;
  top: 2px;
}
.login__signup {
  margin-top: 16px;
  margin-bottom: 56px;
  text-align: center;
  color: #8d8d8d;
  font-size: 14px;
}
.login__signup a {
  margin-left: 8px;
}
.login--submit::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 20px;
  margin-right: 3px;
  background-image: url("~@/assets/images/accounts/icon-login-button.svg");
  background-size: 16px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -4px;
}
</style>
